import { FlowDataDoc, Step } from '@arcadehq/shared/types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { Flow, FlowData, FlowPublicData } from 'src/types'

import {
  getFromSerialized,
  getSerializable,
  SerializableData,
} from './serializable'
import { isStepWithSize } from './steps'

export function getSerializablePublicFlow(
  flow: Flow
): SerializableData<FlowPublicData> {
  return getSerializable({
    ...pick(flow, [
      'id',
      'created',
      'modified',
      'name',
      'description',
      'aspectRatio',
      'cta',
      'steps',
      'createdBy',
      'editors',
      'status',
      'flowWrapper',
      'font',
      'bgImage',
      'group',
      'showArcadeButton',
      'openingAnimation',
      'belongsToTeam',
      'showFlowNavIndicator',
      'optimizeFlowForMobileView',
      'showStartOverlay',
      'startOverlayButtonText',
      'preventIndexing',
      'structureHash',
      'autoplay',
    ]),
    steps: flow.steps.map(step =>
      pick(step, [
        'id',
        'type',
        'name',
        'targetId',
        'hotspots',
        'size',
        // Image
        'url',
        'blurhash',
        'panAndZoom',
        // Video
        'streamUrl',
        'videoThumbnailUrl',
        'playbackRate',
        'assetId',
        'startTimeFrac',
        'endTimeFrac',
        'duration',
        'muted',
        'videoProcessing',
        // Overlay
        'title',
        'subtitle',
        'actionUrl',
        'buttonText',
        'buttonColor',
        'buttonTextColor',
        'buttonType',
        'theme',
        'blur',
        'audioUrl',
        // Legacy
        'cta',
      ])
    ),
  })
}

export function getFlowFromSerializablePublicFlow(
  publicFlow: SerializableData<FlowPublicData>
): Flow {
  const deserializedFlow = getFromSerialized<FlowPublicData>(publicFlow)
  return {
    ...deserializedFlow,
    schemaVersion: '',
    experiments: [],
    experimentsConfig: {},
    gifUrl: '',
    videoUrl: '',
    checksum: '',
    tagIds: [],
    publishedDate: new Date(),
    lastModifiedBy: '',
    uploadId: '',
    update: async (entity: Partial<FlowData>, userId: string | null) => {
      if (!userId) return false
      // ensure firebase is not bundled in the viewer
      const { updateFlow } = await import('src/store/flows')
      return updateFlow(deserializedFlow.id, entity, userId)
    },
  }
}

// TODO: Ideally we should share the `Flow` type between apps
// and not have to do this.
export function getFlowDataDocFromFlow(flow: Flow): FlowDataDoc {
  return {
    ...omit(flow, ['id', 'created', 'modified', 'createdBy', 'lastModifiedBy']),
  }
}

export const getFirstStepWithSize = (steps: Step[]) => {
  return steps.find(isStepWithSize)
}
