"use strict";
exports.__esModule = true;
exports.Feature = void 0;
var Feature;
(function (Feature) {
    Feature["NoArcadeBranding"] = "No Arcade Branding";
    Feature["Analytics"] = "Analytics";
    Feature["PublishEventsToHost"] = "Publish Events to Host";
    Feature["DisableIPTracking"] = "Disable IP Tracking";
    Feature["PreventIndexing"] = "Prevent Indexing";
    Feature["DoNotTrack"] = "Do Not Track";
    Feature["DisableInternal3rdPartyTrackers"] = "Disable Internal 3rd Party Trackers";
    Feature["PaywallArcadeLimit"] = "Paywall Arcade Limit";
    Feature["FlowInsights"] = "Flow Insights";
    Feature["EditAccessToArcadesIsControlled"] = "Edit Access to Arcades is Controlled";
    // Temporary features:
    // To disable a temporary feature:
    // - Remove it from the code
    // - Remove it from Firestore (userProfiles, Teams and Global)
    // - Remove it from Retool
    Feature["BrowserBackForwardButtons"] = "Display Browser Back/Forward Buttons";
    Feature["CalloutShape"] = "Callout Shape";
    Feature["ChapterAudio"] = "Chapter Audio";
})(Feature = exports.Feature || (exports.Feature = {}));
